import React from 'react';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Checkbox, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const MultiColumnList = (props: any): JSX.Element => {
    const renderColumns = (items: any, columnSize: number, itemWidth: string): JSX.Element[] => {
        const numItems: number = items?.length;
        const numColumns: number = Math.ceil(numItems / columnSize);
        const columns: any[] = [];

        for (let i = 0; i < numColumns; i++) {
            const startIndex = i * columnSize;
            const endIndex = Math.min(startIndex + columnSize, numItems);
            const columnItems = items.slice(startIndex, endIndex);
            const column: any = (
                <ul key={i} style={{ margin: '0', padding: '0', width: itemWidth, flexBasis: itemWidth }}>
                    {columnItems.map(
                        (legend: any): JSX.Element => (
                            <InfoListItem
                                key={legend?.channelId}
                                title={legend?.name}
                                className={props?.isDisabled ? 'disabled' : ''}
                                backgroundColor={legend.visible ? `${legend?.color}30` : ''}
                                icon={
                                    <Checkbox
                                        checked={legend.visible}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            props.changeHandler(e, legend?.channelId)
                                        }
                                        disabled={props?.isDisabled}
                                        sx={{
                                            color: 'withe',
                                            '&.Mui-checked': {
                                                color: legend?.color ?? 'white',
                                            },
                                        }}
                                    />
                                }
                                statusColor={legend.visible ? legend.color : ''}
                                sx={{ height: '54px' }}
                            />
                        )
                    )}
                </ul>
            );

            columns.push(column);
        }

        return columns;
    };
    const ulWidth = `${100 / Math.ceil(props?.legendData?.length / props?.maxItemsPerColumn)}%`;
    const columns = renderColumns(props?.legendData, props?.maxItemsPerColumn, ulWidth);

    return props?.isLoading ? (
        <div>
            <Stack sx={{ margin: '5px' }} spacing={1} width="100%">
                <Skeleton animation="pulse" variant="text" width="100%" height={30} />
                <Skeleton animation="wave" variant="text" width="100%" height={30} />
                <Skeleton animation="pulse" variant="text" width="100%" height={30} />
                <Skeleton animation="wave" variant="text" width="100%" height={30} />
                <Skeleton animation="pulse" variant="text" width="100%" height={30} />
            </Stack>
        </div>
    ) : (
        <div className="d-flex">{columns}</div>
    );
};
